.box {
  clip-path: ellipse(62% 73% at 50% 26%);
}
.box-1 {
  clip-path: ellipse(149% 73% at 50% 33%);
}

.scrolling-animation {
  height: 100vh; /* Adjust the height as needed */
  background-color: #3498db; /* Background color for the animated div */
  transition: clip-path 0.3s ease-in-out;
  overflow: hidden;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px linear-gradient(to right, #525252, #18181b)
    inset;
  transition: background-color 5000s ease-in-out 0s;
}

#qr {
  width: 157px !important;
}

.mounting-point {
  margin-left: -1000px;
}

.selected {
  border: 2px solid #13b348b6;
}

.empty_div {
  opacity: 0;
}

.pay-now-btn {
  box-shadow: 4px 4px 10.699999809265137px 0px #00000040;
  box-shadow: 3px 3px 4px 0px #ffffff66 inset;
}
.pay-now-btn:disabled {
  background-color: #b8b8b8;
}

body {
  background-color: #f2f5ff;
}

.home-screen {
  box-shadow: 0px 4px 20px 10px #00000012;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #c1c1c17b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 0px;
}

.swiper-wrapper {
  padding: 10px 2px;
  /* height: 50px !important; */
  margin: 10px 0;
}
