@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.card-bg {
  background: linear-gradient(0deg, rgba(7, 7, 7, 0.7), rgba(7, 7, 7, 0.7)),
    radial-gradient(
      94.96% 94.96% at -3.11% -1.41%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.25) inset;
}
.front,
.back {
  position: absolute;
  height: 16rem;
  width: 100%;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.credit-card .front {
  transform: rotateY(0deg);
  transition: transform 0.5s;
}

.credit-card .back {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.credit-card.flipped .front {
  transform: rotateY(-180deg);
}

.credit-card.flipped .back {
  transform: rotateY(0deg);
}
